import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

const SEO = (props: SEOInterface) => {
   const { site, allFile } = useStaticQuery(
      graphql`
         query {
            site {
               siteMetadata {
                  title
                  description
                  author
                  siteUrl
               }
            }
            allFile(filter: { name: { eq: "logo_1x1" } }) {
               nodes {
                  relativePath
                  publicURL
               }
            }
         }
      `
   );

   const description = props.description;
   const title =
      props.title || "CAJA DE COMPENSACIÓN FAMILIAR DE ANTIOQUIA - COMFAMA";
   let metaDescription = description || site.siteMetadata.description;
   let author = site.siteMetadata.author;
   let siteUrl = site.siteMetadata.siteUrl;
   let ogImageUrl = props.image
      ? `https:${props.image}`
      : siteUrl + allFile.nodes[0].publicURL;
   const isArticle = props.isArticle;
   const titleArticle = props.titleArticle;
   const datePublished = props.datePublished;
   const slug = props.slug || "";
   const noIndex = props.noIndex;
   const noCanonical = props.noCanonical;
   const urlCanonical = props.urlCanonical;
   const url = typeof window !== "undefined" ? window.location.href : "";

   // Organization Schema ----------------------------------
   const org = {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "COMFAMA",
      alternateName: author,
      url: siteUrl,
      logo: siteUrl + allFile.nodes[0].publicURL,
      contactPoint: {
         "@type": "ContactPoint",
         telephone: "01 8000 124 477",
         contactType: "customer service",
         contactOption: "TollFree",
         areaServed: "CO",
         availableLanguage: "es",
      },
      sameAs: [
         "https://www.facebook.com/comfama/",
         "https://twitter.com/Comfama/",
         "https://www.instagram.com/comfama/",
         "https://www.youtube.com/user/ComfamaCCF/",
         "https://www.linkedin.com/company/comfama/",
      ],
   };
   const schemaOrgJSONLD = {
      type: "application/ld+json",
      innerHTML: JSON.stringify(org),
   };

   // WebSite Schema ----------------------------------
   const website = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "@id": siteUrl + "#website",
      headline: "Comfama",
      name: "Comfama",
      description: site.siteMetadata.description,
      url: siteUrl,
      potentialAction: {
         "@type": "SearchAction",
         target: siteUrl + "?s={search_term_string}",
         "query-input": "required name=search_term_string",
      },
   };
   const schemaWebSiteJSONLD = {
      type: "application/ld+json",
      innerHTML: JSON.stringify(website),
   };

   // Local Business Schema ----------------------------------
   const localBusiness = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: "Comfama",
      image: siteUrl + allFile.nodes[0].publicURL,
      "@id": siteUrl + "#website",
      url: siteUrl,
      telephone: "01 8000 124 477",
      address: {
         "@type": "PostalAdress",
         streetAddress: "Cra. 48 #20-114",
         addressLocality: "Medellín",
         postalCode: "050021",
         addressCountry: "Colombia",
      },
      openingHoursSpecification: {
         "@type": "OpeningHoursSpecification",
         dayOfWeek: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
         ],
         opens: "08:00",
         closes: "16:00",
      },
      sameAs: [
         "https://www.facebook.com/comfama/",
         "https://twitter.com/Comfama/",
         "https://www.instagram.com/comfama/",
         "https://www.youtube.com/user/ComfamaCCF/",
         "https://www.linkedin.com/company/comfama/",
      ],
   };
   const schemaLocalBusinessJSONLD = {
      type: "application/ld+json",
      innerHTML: JSON.stringify(localBusiness),
   };

   // Blog Posting Schema ----------------------------------
   const blogPosting = {
      "@context": "https://schema.org",
      "@type": "Article",
      headline: titleArticle,
      image: ogImageUrl,
      author: {
         "@type": "Organization",
         name: author,
      },
      publisher: {
         "@type": "Organization",
         name: "Comfama",
         logo: {
            "@type": "ImageObject",
            url: siteUrl + allFile.nodes[0].publicURL,
         },
      },
      datePublished: datePublished,
      dateModified: datePublished,
   };

   const blogPostingJSONLD = {
      type: "application/ld+json",
      innerHTML: JSON.stringify(blogPosting),
   };

   let schemas;

   if (isArticle) {
      schemas = [
         schemaOrgJSONLD,
         schemaWebSiteJSONLD,
         schemaLocalBusinessJSONLD,
         blogPostingJSONLD,
      ];
   } else {
      schemas = [
         schemaOrgJSONLD,
         schemaWebSiteJSONLD,
         schemaLocalBusinessJSONLD,
      ];
   }

   return (
      <Helmet script={schemas} defer={false}>
         <title>{title}</title>
         {props.fbId &&
            <meta property="fb:app_id" content={props.fbId} />
         }
         {noCanonical === false ||
            noCanonical === undefined ||
            (noCanonical === null && (
               <link
                  rel="canonical"
                  href={urlCanonical ? urlCanonical : url}
                  data-baseprotocol="https:"
                  data-basehost="www.comfama.com"
               />
            ))}
         {title === "Resultados " ||
            (noIndex && <meta name="robots" content="noindex"></meta>)}
         <link rel="icon" href="/favicon.ico" type="image/png" />
         <meta property="image" content={ogImageUrl} />
         <meta property="og:title" content={title} />
         <meta property="og:description" content={metaDescription} />
         <meta property="og:image" content={ogImageUrl} />
         <meta property="og:image:width" content="400" />
         <meta property="og:image:height" content="300" />
         <meta property="og:type" content="website" />
         <meta property="og:url" content={siteUrl + slug} />
         <meta name="description" content="metaDescription" />
         <meta
            name="facebook-domain-verification"
            content={process.env.FACEBOOK_DOMAIN_VERIFICATION}
         />
         <meta name="twitter:card" content="summary" />
         <meta name="twitter:creator" content={author} />
         <meta name="twitter:title" content={title} />
         <meta name="twitter:description" content={metaDescription} />
         <meta name="twitter:image" content={ogImageUrl} />
         <meta name="robots" content="max-image-preview:large" />
         <meta
            name="google-site-verification"
            content={process.env.GOOGLE_SITE_VERIFICATION}
         />
      </Helmet>
   );
};

interface SEOInterface {
   title: string;
   description?: any;
   lang?: string;
   meta?: Array<any>;
   image?: string;
   isArticle?: boolean;
   titleArticle?: string;
   datePublished?: string;
   slug?: string;
   noCanonical?: boolean;
   urlCanonical?: string;
   noIndex?: boolean;
   fbId?: string;
}

export default SEO;
